import vue from "vue";
import VueRouter from "vue-router";
vue.use(VueRouter);

// 导航到当前路由报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  // pc
  //   {
  // path: "/",
  // redirect: "/",
  //   },
  //   {
  //     path: '/hfPage',
  //     name: 'hfPage',
  //     component: () =>
  //         import ('@/views/index'),
  // },
  {
    path: '/have-fun/:id/:lang?',
    name: 'have-fun-nav',
    component: () => import('@/views/newIndex_nav'),
  },
  {
    path: '/have-fun',
    name: 'have-fun',
    component: () => import('@/views/newIndex_home'),
  },
  {
    path: '/HowtoUse/:id/:lang?',
    name: 'HowtoUse',
    component: () => import('@/views/HowtoUse'),
  },
  {
    path: '/TravelTips/:id',
    name: 'TravelTips',
    component: () => import('@/views/TravelTips'),
  },
  {
    path: '/TravelTips_detail/:id/:lang?',
    name: 'TravelTips_detail',
    component: () => import('@/views/TravelTips_detail'),
  },
  {
    path: '/ReferenceLink/:id',
    name: 'ReferenceLink',
    component: () => import('@/views/ReferenceLink'),
  },
  {
    path: '/have-fun-list/:id/:lang?/:filtPar?',
    name: 'have-fun-list',
    component: () => import('@/views/newIndex_list'),
  },
  {
    path: '/Filter/:id',
    name: 'Filter',
    component: () => import('@/views/Filter'),
  },
  {
    path: '/cp240601-01',
    name: 'cp240601-01',
    component: () => import('@/views/cp/cp240601-01'),
  },
  {
    path: '/cp240701-02',
    name: 'cp240701-02',
    component: () => import('@/views/cp/cp240701-02'),
  },
  {
    path: '/cp240812-01',
    name: 'cp240812-01',
    component: () => import('@/views/cp/cp240812-01'),
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy.vue'),
  },
  // {
  //   path: "*",
  //   component: () => import("@/views/404"),
  // },
  {
    path: '/',
    name: 'tcapp-download-b',
    component: () => import('@/views/appDownloadPageB/index'),
  },
  // {
  //     path: '/sc',
  //     name: 'tcapp-download-bSc',
  //     component: () =>
  //         import ('@/views/appDownloadPageB/indexZh')
  // },
  // {
  //     path: '/tc',
  //     name: 'tcapp-download-bTc',
  //     component: () =>
  //         import ('@/views/appDownloadPageB/indexTw')
  // },
  // {
  //     path: '/en',
  //     name: 'tcapp-download-bEn',
  //     component: () =>
  //         import ('@/views/appDownloadPageB/indexEn')
  // },
  // {
  //     path: '/kr',
  //     name: 'tcapp-download-bKr',
  //     component: () =>
  //         import ('@/views/appDownloadPageB/indexKo')
  // },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.afterEach((to) => {
  // console.log(to, from);
  // console.log(window.ga);
  if (window.ga) {
    window.ga("set", "page", to.fullPath);
    window.ga("send", "pageview");
  }
});

export default router;
